import React, { useEffect, useState } from "react";
import {format} from "date-fns";
import {Link} from "react-router-dom";
interface PromoCode {
    senderBank: string;
    receiverBank: string;
    id: number;
    createdAt: string;
    direction: string;
    amount: number;
    status: number;
}
const History = () => {
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);

  useEffect(() => {
    // Проверка наличия Telegram Web Apps SDK
    if (window.Telegram?.WebApp?.initDataUnsafe) {
      const id = window.Telegram.WebApp.initDataUnsafe.user?.id;
      alert(id);
      if (id) {
        setUserId(id);
      } else {
      }
    } else {
      alert("Telegram Web Apps SDK не инициализирован");
    }
  }, []);

  useEffect(() => {
    // Функция для получения данных
    const fetchPromoCodes = async () => {
      try {
        const response = await fetch(
            "https://promokodiskidki.com/xber/history?userId="+userId
        );

        if (!response.ok) {
          throw new Error(`Ошибка сети: ${response.status}`);
        }

        const data: PromoCode[] = await response.json();
        setPromoCodes(data); // Сохраняем данные в состоянии массива
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      }
    };

    fetchPromoCodes();
  }, [userId]);

  return (
      <>
        <div className="history_block">
          <div className="wrapper">
            <div className="history_items">
              {promoCodes.map((item, index) => (
                  <div className="history_item" key={index}>
                      <Link to="/transfer-details" state={item}>
                          <div className="history_item_title">
                              {item.status === 0 ? (
                                  <div className="history_item_title_processing">
                                      <span>в обработке</span>
                                      <span>
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M12.4594 0.386444C5.7699 0.386444 0.349976 5.80637 0.349976 12.4958C0.349976 19.1853 5.7699 24.6052 12.4594 24.6052C19.1488 24.6052 24.5687 19.1853 24.5687 12.4958C24.5687 5.80637 19.1488 0.386444 12.4594 0.386444ZM15.2474 17.4812L10.9408 14.3513C10.7894 14.239 10.7015 14.0632 10.7015 13.8777V5.65988C10.7015 5.33762 10.9652 5.07394 11.2875 5.07394H13.6312C13.9535 5.07394 14.2172 5.33762 14.2172 5.65988V12.3835L17.3177 14.6394C17.5814 14.8298 17.6351 15.196 17.4447 15.4597L16.0677 17.3542C15.8773 17.613 15.5111 17.6716 15.2474 17.4812Z"
                              fill="#999999"
                          />
                        </svg>
                      </span>
                                  </div>
                              ) : (
                                  <div className="history_item_title">
                                      {format(new Date(item.createdAt), "dd.MM.yyyy HH:mm")}
                                  </div>
                              )
                              }

                          </div>
                          <div className="history_item_row">
                              <div className="history_item_ico">
                                  <svg
                                      width="26"
                                      height="26"
                                      viewBox="0 0 26 26"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <g clipPath="url(#clip0_88_974)">
                                          <path
                                              d="M0.978638 20.2494C0.978638 21.3995 1.9118 22.3327 3.06197 22.3327H23.8953C25.0455 22.3327 25.9786 21.3995 25.9786 20.2494V12.6105H0.978638V20.2494ZM9.31197 17.298C9.31197 17.0115 9.54635 16.7771 9.8328 16.7771H15.7356C16.022 16.7771 16.2564 17.0115 16.2564 17.298V19.0341C16.2564 19.3205 16.022 19.5549 15.7356 19.5549H9.8328C9.54635 19.5549 9.31197 19.3205 9.31197 19.0341V17.298ZM3.75642 17.298C3.75642 17.0115 3.99079 16.7771 4.27725 16.7771H7.40225C7.68871 16.7771 7.92308 17.0115 7.92308 17.298V19.0341C7.92308 19.3205 7.68871 19.5549 7.40225 19.5549H4.27725C3.99079 19.5549 3.75642 19.3205 3.75642 19.0341V17.298ZM25.9786 4.97158V7.05491H0.978638V4.97158C0.978638 3.8214 1.9118 2.88824 3.06197 2.88824H23.8953C25.0455 2.88824 25.9786 3.8214 25.9786 4.97158Z"
                                              fill="black"
                                          />
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_88_974">
                                              <rect
                                                  width="25"
                                                  height="25"
                                                  fill="white"
                                                  transform="translate(0.978638 0.110474)"
                                              />
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                              <div className="history_item_desc">
                                  <p>с карты {item.senderBank}</p>
                                  <p>на карту {item.receiverBank}</p>
                              </div>
                              {item.direction === 'KZ' ?
                                  <div className="history_item_sum">
                                      <p>{item.amount} руб.</p>
                                      <p>{item.amount * 5} тнг.</p>
                                  </div>
                                  : <div className="history_item_sum">
                                      <p>{item.amount * 5} тнг.</p>
                                      <p>{item.amount} руб.</p>


                                  </div>}


                          </div>
                      </Link>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </>
  );
};

export default History;