import React, { useState } from "react";

const FAQ = () => {
  return (
    <>
      <div className="wrapper">Здесь будут часто задаваемые вопросы</div>
    </>
  );
};

export default FAQ;
