// @ts-ignore
document.querySelectorAll<HTMLElement>('.btn_kz').forEach((button) => {
    button.addEventListener('click', function () {
        // Удаляем класс у всех кнопок
        document.querySelectorAll<HTMLElement>('.direction_btn').forEach((btn) => {
            btn.classList.remove('direction_btn_active');
        });
        // Меняем классы у направления
        const directionElement = document.querySelector<HTMLElement>('.direction');
        if (directionElement) {
            directionElement.classList.remove('direction_ru');
            directionElement.classList.add('direction_kz');
        }
        // Добавляем класс к нажимаемой кнопке
        this.classList.add('direction_btn_active');
    });
});

document.querySelectorAll<HTMLElement>('.btn_ru').forEach((button) => {
    button.addEventListener('click', function () {
        // Удаляем класс у всех кнопок
        document.querySelectorAll<HTMLElement>('.direction_btn').forEach((btn) => {
            btn.classList.remove('direction_btn_active');
        });
        // Меняем классы у направления
        const directionElement = document.querySelector<HTMLElement>('.direction');
        if (directionElement) {
            directionElement.classList.remove('direction_kz');
            directionElement.classList.add('direction_ru');
        }
        // Добавляем класс к нажимаемой кнопке
        this.classList.add('direction_btn_active');
    });
});

const copyButton = document.getElementById('copy_numb') as HTMLElement;
if (copyButton) {
    copyButton.addEventListener('click', () => {
        const content = document.getElementById('card_numb')?.textContent;
        if (content) {
            navigator.clipboard.writeText(content);
        }
    });
}

const copyButton1 = document.getElementById('copy_sum_btn') as HTMLElement;
if (copyButton1) {
    copyButton1.addEventListener('click', () => {
        const content1 = document.getElementById('copy_sum')?.textContent;
        if (content1) {
            navigator.clipboard.writeText(content1);
        }
    });
}

document.querySelectorAll<HTMLElement>('.help_item_title').forEach((title) => {
    title.addEventListener('click', function () {
        // Удаляем класс "help_item_active" у всех элементов "help_item"
        document.querySelectorAll<HTMLElement>('.help_item').forEach((item) => {
            item.classList.remove('help_item_active');
        });

        // Добавляем класс "help_item_active" к родительскому элементу заголовка
        this.parentElement?.classList.add('help_item_active');
    });
});

const sliders = document.querySelectorAll<HTMLElement>('.gallery');

sliders.forEach((slider) => {
    let currentSlide = 0;
    const slides = slider.querySelectorAll<HTMLElement>('.gallery_item');
    const totalSlides = slides.length;
    const dotsContainer = slider.querySelector<HTMLElement>('.dots');
    const navigation = slider.querySelector<HTMLElement>('.navigation');
    const prevButton = navigation?.querySelector<HTMLElement>('.prev');
    const nextButton = navigation?.querySelector<HTMLElement>('.next');

    let isMouseDown = false;
    let startX: number;
    let scrollLeft: number;

    function updateDots(index: number) {
        if (!dotsContainer) return;
        dotsContainer.innerHTML = '';
        for (let i = 0; i < totalSlides; i++) {
            const dot = document.createElement('span');
            dot.className = 'dot' + (i === index ? ' active' : '');
            dot.addEventListener('click', () => {
                currentSlide = i;
                updateSlider();
            });
            dotsContainer.appendChild(dot);
        }
    }

    function updateSlider() {
        const slideWidth = slides[0]?.offsetWidth || 0;
        slider.scrollLeft = currentSlide * slideWidth; // Прокручиваем слайдер
        updateDots(currentSlide);
        updateActiveClass(currentSlide); // Обновляем активный класс
    }

    function updateActiveClass(index: number) {
        slides.forEach((slide) => slide.classList.remove('gallery_item_active')); // Убираем активный класс
        slides[index]?.classList.add('gallery_item_active'); // Добавляем активный класс к текущему слайду
    }

    function scrollSlider(e: MouseEvent) {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft; // Получаем позицию мыши
        const walk = (x - startX) * 2; // Прокрутка в два раза быстрее
        slider.scrollLeft = scrollLeft - walk;
    }

    slider.addEventListener('mousedown', (e) => {
        isMouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
        slider.style.cursor = 'grabbing'; // Меняем курсор
    });

    slider.addEventListener('mouseleave', () => {
        isMouseDown = false;
        slider.style.cursor = 'grab'; // Возвращаем курсор
    });

    slider.addEventListener('mouseup', () => {
        isMouseDown = false;
        slider.style.cursor = 'grab'; // Возвращаем курсор
    });

    slider.addEventListener('mousemove', scrollSlider);

    // Обработка прокрутки колёсиком мыши
    slider.addEventListener('wheel', (e) => {
        e.preventDefault(); // Предотвращаем прокрутку страницы
        slider.scrollLeft += e.deltaY; // Прокручиваем в соответствии с движением колёсика
    });

    prevButton?.addEventListener('click', () => {
        currentSlide = currentSlide === 0 ? totalSlides - 1 : currentSlide - 1;
        updateSlider();
    });

    nextButton?.addEventListener('click', () => {
        currentSlide = (currentSlide + 1) % totalSlides;
        updateSlider();
    });

    updateDots(currentSlide); // Инициируем точки
    updateSlider(); // Инициируем отображение
    updateActiveClass(currentSlide); // Устанавливаем активный класс
});

const approvalCheckbox = document.getElementById('approval') as HTMLInputElement | null;
const submitButton = document.getElementById('submit') as HTMLInputElement | null;

approvalCheckbox?.addEventListener('click', function () {
    // Проверяем, отмечен ли чекбокс
    if (approvalCheckbox.checked) {
        submitButton?.removeAttribute('disabled'); // включаем input
    } else {
        submitButton?.setAttribute('disabled', 'true'); // отключаем input
    }
});