export default function Header() {
  return (
    <header>
      <div className="wrapper">
        <div className="header_items">
          <div className="header_left">
            <div className="logo">
              <div className="logo_title">xber</div>
              <div className="logo_desc">Ничего лишнего - просто переводы.</div>
            </div>
          </div>
          <div className="header_right">
            <a href="/history">
              <img src="/img/history_icon.svg" alt="icon" />
            </a>
            <a href="/faq">
              <img src="/img/question_icon.svg" alt="icon" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
