import React, { useState } from "react";
import {useLocation} from "react-router-dom";
import {format} from "date-fns";

const TransferDetails = () => {
    const location = useLocation();
    const data = location.state;
    return (
        <>
            <div className="info_block">
                <div className="wrapper">
                    <div className="info">
                        {data.status === 0 ? (
                            <div className="info_header gray">
                                <div className="info_header_title">Перевод в обработке</div>
                                <div className="info_header_numb">{data.amount} {data.direction === 'RU' ? 'тнг' : 'руб'}.</div>
                            </div>
                        ) : data.status === 2 ? (
                            <div className="info_header green">
                                <div className="info_header_title">Перевод успешно выполнен</div>
                                <div className="info_header_numb">{data.amount} {data.direction === 'RU' ? 'тнг' : 'руб'}.</div>
                            </div>
                        ) : (
                            <div className="info_header red">
                                <div className="info_header_title">Перевод отклонен</div>
                                <div className="info_header_numb">{data.amount} {data.direction === 'RU' ? 'тнг' : 'руб'}.</div>
                            </div>
                        )}

                        <div className="info_items">
                            <div className="info_item">
                                <div className="info_item_title">Тип перевода</div>
                                <div className="info_item_desc">{data.senderBank} - {data.receiverBank}</div>
                            </div>
                            <div className="info_item">
                                <div className="info_item_title">№ квитанции</div>
                                <div className="info_item_desc">{data.id}</div>
                            </div>
                            <div className="info_item">
                                <div className="info_item_title">Дата и время</div>
                                <div className="info_item_desc">{format(new Date(data.createdAt), "dd.MM.yyyy HH:mm")}</div>
                            </div>
                            <div className="info_item">
                                <div className="info_item_title">Комиссия</div>
                                <div className="info_item_desc">{data.amount / 50} руб.</div>
                            </div>
                            <div className="info_item">
                                <div className="info_item_title">Сумма в валюте получателя</div>
                                <div className="info_item_desc">{data.amount} тнг.</div>
                            </div>
                            <div className="info_item">
                                <div className="info_item_title">Номер карты получателя</div>
                                <div className="info_item_desc">{data.receiverCardNumber.slice(-4).padStart(data.receiverCardNumber.length, "*")}</div>
                            </div>
                        </div>
                        <div className="info_btn">
                            <a href="/history" className="btn btn_white">Назад</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransferDetails;

